.no-team-section {
  display: grid;
  max-height: 95vh;
  min-height: 80vh;
  grid-template-rows: 1.2fr auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "blank1 blank blank2"
    "blank1 headline blank2"
    "blank1 button blank2";
  grid-row-gap: 1rem;

  & > :nth-child(1) {
    grid-area: headline;
    align-self: end;
    justify-self: center;
    text-align: center;
  }

  & > :nth-child(2) {
    grid-area: button;
    align-self: start;
    justify-self: center;
  }

  @media (--viewport-m) {
    grid-row-gap: 2rem;
  }
}

.button-bigger-on-bigger-screen {
  @media (--viewport-m) {
    display: block;
    width: 30rem;
  }
}

.headline-wrapper {
  display: inline-block;

  & > :nth-child(1) {
    color: var(--dark-font-color) !important;
  }

  @media (--viewport-m) {
    width: 30rem;
  }
}

.no-team-section-background {
  /* The image used */
  height: 95vh;
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("./../../assets/images/no-team.png");
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  filter: brightness(60%) blur(6px);

  /* Full height */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
