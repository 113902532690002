.logo-wrapper {
  display: grid;
  background: transparent;
  border-radius: 3px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  border-radius: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
}

@keyframes pulse {
  to {
    opacity: 0;
    transform: scale(1);
  }
}

.logo-wrapper::before,
.logo-wrapper::after {
  animation: pulse 3s ease-in infinite;
  border: var(--selected-action-color-3) solid 3px;
  border-radius: 100%;
  box-sizing: border-box;
  content: " ";
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  transform: scale(1.12);
  width: 100%;
  z-index: 1;
}

.logo-wrapper::after {
  animation-delay: 1.5s;
}

.logo-wrapper:hover::after {
  animation-delay: 0.5s;
}
