:root {
  --light-background-color: #fdfffc;
  --dark-background-color: #011627;
  --light-font-color: #011627;
  --dark-font-color: #fdfffc;
  --dark-action-color: #fdfffc;
  --light-action-color: #011627;
  --selected-action-color-1: #efd9ce;
  --selected-action-color-2: #e71d36;
  --selected-action-color-3: #ff9f1c;
  --selected-action-color-4: #011627;
  --selected-action-color-5: #011627;

  /* --selected-design-font: "Major Mono Display", monospace; */
  --selected-error-color: orangered;
  --selected-info-color: mediumseagreen;
  --font-bold: 800;
  --font-bolder: 500;
  --font-regular: 300;
  --font-size-large: calc(var(--brand-font-size) + 1rem + 0.4vw);
  --font-size-medium: calc(var(--brand-font-size) + 0.7rem + 0.3vw);
  --font-size-small: calc(var(--brand-font-size) + 0.5rem + 0.2vw);
}

h1 {
  @media (--viewport-m) {
    margin-left: 0;
  }
}

p {
  padding-bottom: 0.5rem;
}

.custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;

  @media (--viewport-s) {
    height: 100vh;
  }
}

.custom-scroll::-webkit-scrollbar {
  @media (--viewport-s) {
    scrollbar-width: 6px;
    width: 6px;
    height: 5px;
  }
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: var(--selected-pure-color);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--selected-action-color);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: var(--selected-scrollbar-margin-top, 0);
}

.custom-scroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: var(--selected-scrollbar-margin-bottom, 0);
}

ul > li {
  color: initial !important;
}

ol > li {
  color: initial !important;
}

.swiper-slide {
  margin-bottom: 2rem !important;
}
