/* stylelint-disable no-descending-specificity */
.app-layout-grid {
  display: grid;
  min-height: 100vh;
  max-width: 100vw;
  grid-template-areas:
    "main"
    "footer";

  /* disable text selection for more native feeling */

  /* user-select: none; */

  /* disable text highlighting for more native feeling */
  -webkit-tap-highlight-color: transparent;

  /* disable callout action for more native feeling */
  -webkit-touch-callout: none;

  & > :nth-child(1) {
    grid-area: main;
    align-self: start;
    justify-self: center;
    max-width: 300rem;
    padding-bottom: 7rem;
  }

  & > :nth-child(2) {
    position: fixed;
    grid-area: footer;
    align-self: center;
    justify-self: center;
    max-width: 300rem;
    width: 100vw;
    left: 50%;
    bottom: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    transform: translateX(-50%);
    border-top: 1px solid var(--selected-shadow-color);
    background-color: var(--selected-pure-color);
    box-shadow: 0 4px 8px 0 var(--selected-shadow-color), 0 6px 20px 0 var(--selected-shadow-color);
    z-index: 10;
  }
}

.user-avatar {
  border-radius: 100%;
}

/* width */
.scroll-to-bottom {
  --selected-scrollbar-margin-bottom: 0;
}
