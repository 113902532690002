.navbarapp-grid {
  display: grid;
  grid-template-rows: repeat(1, minmax(min-content, max-content));
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 1rem;
  grid-template-areas: "team home user";

  & > :nth-child(n) {
    align-self: center;
    justify-self: center;
  }
}

.icon-wrapper {
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  color: var(--selected-font-color);
  max-width: 2.5rem;

  & > :nth-child(1) {
    cursor: pointer;
    background-color: transparent;
    border: 0;
  }

  @media (--viewport-m) {
    &:hover {
      border-bottom: 2px solid var(--selected-action-color) !important;
    }
  }
}

.active {
  border-bottom: 2px solid var(--selected-action-color) !important;
}
